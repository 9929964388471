import request from '@/utils/request'

//资产详 情
export const accountDetail = (data) => {
  return request({
    url: '/account/detail',
    method: 'get',
    params:data
  })
}
//资产列表
export const accountlist = (data) => {
  return request({
    url: '/account/list',
    method: 'get',
    params:data
  })
}
//资产总额
export const accountAsset = (data) => {
  return request({
    url: '/account/asset',
    method: 'get',
    params:data
  })
}
//用户信息
export const userInfo = (data) => {
  return request({
    url: '/user/info',
    method: 'get',
    params:data
  })
}
//新闻公告
export const newlist = (data) => {
  return request({
    url: '/news/list',
    method: 'get',
    params:data
  })
}
//新闻详情
export const newDet = (data) => {
  return request({
    url: '/news/info',
    method: 'get',
    params:data
  })
}
//现货历史订单
export const leverHistory = (data) => {
  return request({
    url: '/tx_history/list',
    method: 'get',
    params:data
  })
}
//合约历史订单
export const contractHistory = (data) => {
  return request({
    url: '/lever/my_trade',
    method: 'post',
    data:data
  })
}

//充值明细
export const accountChange = (data) => {
  return request({
    url: '/account_log/change',
    method: 'get',
    params:data
  })
}

//合约划转记录
export const leverChange = (data) => {
  return request({
    url: '/account_log/lever',
    method: 'get',
    params:data
  })
}

//秒合约划转记录
export const microChange = (data) => {
  return request({
    url: '/account_log/micro',
    method: 'get',
    params:data
  })
}


//充币币种列表
export const rechargeCurrencyList = (data) => {
  return request({
    url: '/wallet/recharge_currency_list',
    method: 'get',
    params:data
  })
}

//获取钱包地址
export const walletWallet = (data) => {
  return request({
    url: '/wallet/wallet',
    method: 'get',
    params:data
  })
}

//虚拟币币种列表
export const currencyProtocols = (data) => {
  return request({
    url: '/block_chain/currency_protocols',
    method: 'get',
    params:data
  })
}

//提币地址列表
export const addressItem = (data) => {
  return request({
    url: '/address/item',
    method: 'get',
    params:data
  })
}

//提币
export const accountDraw = (data) => {
  return request({
    url: '/account/draw',
    method: 'post',
    data:data
  })
}

//删除提币地址
export const addressRemove = (data) => {
  return request({
    url: '/address/remove',
    method: 'post',
    data:data
  })
}

//添加提币地址
export const addressCreate = (data) => {
  return request({
    url: '/address/create',
    method: 'post',
    data:data
  })
}

//划转
export const accountTransfer = (data) => {
  return request({
    url: '/account/transfer',
    method: 'post',
    data:data
  })
}

//设置支付密码
export const amendPayPassword = (data) => {
  return request({
    url: '/user/amend_pay_password',
    method: 'post',
    data:data
  })
}


//设置支付密码
export const imgUpload = (data) => {
  return request({
    url: '/common/image_upload',
    method: 'post',
    headers:{
      'Content-Type': 'multipart/form-data'
    },
    data:data
  })
}

//实名认证
export const realName = (data) => {
  return request({
    url: '/user_real/real_name',
    method: 'post',
    data:data
  })
}

//合约开仓
export const tradeSubmit = (data) => {
  return request({
    url: '/lever/submit',
    method: 'post',
    data:data
  })
}

//现货买入  
export const txInAdd = (data) => {
  return request({
    url: '/tx_in/add',
    method: 'post',
    data:data
  })
}
//现货卖出   
export const txOutAdd = (data) => {
  return request({
    url: '/tx_out/add',
    method: 'post',
    data:data
  })
}

//闪兑   
export const availablCurrencyItem = (data) => {
  return request({
    url: '/market/available_currency_item',
    method: 'get',
    params:data
  })
}


//离线充值   
export const offlineRecharge = (data) => {
  return request({
    url: '/wallet/offline-recharge',
    method: 'post',
    data:data
  })
}




